.footer-top {
    background-color: #eef3fa; 
}
  
.footer-bottom {
    background-color: #eaeef6;
    padding: 29px 0; 
}
.footer-bottom p {
    margin-bottom: 0;
    font-size: 13px;
    color: #677294; 
}
.footer-bottom p span, .footer-bottom p i {
    margin-right: 4px; 
}
  
.footer-logo {
    display: block;
    margin-bottom: 35px; 
}
  
.footer-contact-info {
    margin-bottom: 30px; 
}
.footer-contact-info li {
    color: #051441;
    line-height: 2;
    font-size: 15px; 
}
.footer-contact-info li a {
    color: #051441; 
}
  
.footer-email-form {
    position: relative;
    max-width: 270px;
    width: 100%;
    margin: auto; 
}
@media (min-width: 768px) {
    .footer-email-form {
        margin: unset; 
    } 
}
.footer-email-form input {
    width: 100%;
    padding: 16px 25px;
    border: 1px solid #e2e7f3;
    background-color: #e9ecf3;
    outline: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; 
}
.footer-email-form input:focus, .footer-email-form input:active, .footer-email-form input:hover {
    border-color: #273167; 
}
.footer-email-form svg {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -9px; 
}
  
.footer-widget .footer-title {
    margin-bottom: 40px; 
}
.footer-widget ul li {
    padding: 14px 0;
    font-weight: 500;
    font-size: 14px; 
}
.footer-widget ul li a {
    color: #677294;
}
.footer-widget ul li a:hover {
    color: #273167; 
}
.footer-widget .play-store .custom-btn {
    padding: 10px 20px;
    border: 1px solid #273167;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 3px; 
    min-width: 180px;
    margin-bottom: 30px;
    display: block;
    margin-right: 10px;
    margin-left: 10px;
}
.footer-widget .play-store .custom-btn svg {
    font-size: 27px;
    margin-right: 10px;
    fill: #273167;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; 
}
.footer-widget .play-store .custom-btn p {
    font-size: 12px;
    margin: 0;
    line-height: 1.4;
    color: #273167;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; 
}
.footer-widget .play-store .custom-btn p span {
    display: block;
    font-size: 17px;
    color: #273167;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; 
}
.footer-widget .play-store .custom-btn:hover {
    background-color: #273167; 
}
.footer-widget .play-store .custom-btn:hover svg {
    fill: #fff; 
}
.footer-widget .play-store .custom-btn:hover p {
    color: #fff; 
}
.footer-widget .play-store .custom-btn:hover p span {
    color: #fff; 
}



@media (min-width: 768px){
    .footer-widget .play-store .custom-btn {
        margin-right: 0px;
        margin-left: 0px;
    }
}

.home3 .footer-widget .play-store .custom-btn:hover {
    background-color: #01ce5b;
    border-color: #01ce5b;
}
.home3 .footer-widget .play-store .custom-btn:first-child {
    background-color: #01ce5b;
    border-color: #01ce5b;
}
.home3 .footer-widget .play-store .custom-btn:first-child svg {
    fill: #fff;
}
.home3 .footer-widget .play-store .custom-btn:first-child p span,
.home3 .footer-widget .play-store .custom-btn:first-child p {
    color: #fff;
}
.home3 .to-top {
    background-color: #01ce5b;
}
.footer-share li {
    padding: 0 !important;
}
.footer-share li a {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
    background-color: #01ce5b;
    color: white !important;
    display: block;
    margin: 2px;
    font-size: 12px;
  }
  .footer-share li a:hover {
    background-color: #273167;
  }
  .home3 .footer-contact-info {
      margin-bottom: 10px;;
  }

.get-ready-section {
    background-image: linear-gradient(#03b04f, #01d55e);
  }
  
  .grs-content h3 {
    font-size: 30px;
    font-weight: 500;
    color: white;
  }
  .grs-content p {
    font-size: 16px;
    line-height: 24px;
    color: white;
    margin-bottom: 0;
  }
  
  .grs-btn {
    padding: 15px 40px;
    background-color: white;
    border-radius: 3px;
    display: inline-block;
    color: #01d85f;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .grs-btn:hover {
    transform: translateY(-5px);
  }
  
.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 40px 20px;
}
.header--fixed.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 0;
    z-index: 999;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
}
.axsis-main-menu-area .navbar-brand1 {
    display: block;
}
.axsis-main-menu-area .navbar-brand2 {
    display: none;
}
.header--fixed.sticky.axsis-main-menu-area .navbar-brand1 {
    display: none;
}
.header--fixed.sticky.axsis-main-menu-area .navbar-brand2 {
    display: block;
}
.menu-bar {
    width: 45px;
    height: 40px;
    position: relative;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.menu-bar span {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30px;
    height: 3px;
    margin-top: -1px;
    margin-left: -15px;
    background-color: #fff;
}
.menu-bar span:nth-child(1) {
    margin-top: -9px;
}
.menu-bar span:nth-child(3) {
    margin-top: 7px;
}

/* nav-menu */
.nav-menu li a {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    display: block;
}
.nav-menu li.search-option {
    padding: 10px 15px;
    font-size: 16px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    padding-right: 0;
}
.header--fixed.sticky .nav-menu li.search-option,
.header--fixed.sticky .nav-menu li a {
    color: #273167;
}


.nav-menu li a span {
    position: relative;
}
.nav-menu li a span:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 1px;
    background-color: #fff;
    opacity: 0.4;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.nav-menu li a:hover span:after, .nav-menu li.is-current>a span:after {
    width: 100%;
}
.header--fixed.sticky .nav-menu li a span:after {
    background-color: #273167;
}
.header--fixed.sticky.axsis-main-menu-area .menu-bar span {
    background-color: #273167;
}


/* headerstyle 2 */
.header-style-2 .nav-menu li.search-option,
.header-style-2 .nav-menu li a {
    color: #273167;
}

.header-style-2 .nav-menu li a span:after {
    background-color: #273167;
}
.header-style-2 .axsis-main-menu-area .navbar-brand1 {
    display: none;
}
.header-style-2 .axsis-main-menu-area .navbar-brand2 {
    display: block;
}
.header-style-2 .axsis-main-menu-area .menu-bar span {
    background-color: #273167;
}


@media (min-width: 992px){
    .nav-menu li a {
        padding: 10px 10px;
    }
    /* submenu */
    .has-childmenu {
        position: relative;
    }
    .has-childmenu .submenu {
        list-style: none;
        position: absolute;
        left: 0;
        top: 100%;
        min-width: 220px;
        background-color: #fff;
        padding: 5px 0;
        box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
        border-radius: 3px;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 0.3s ease;
    }
    .has-childmenu .submenu li a {
        font-size: 85%;
        color: #273167;
        padding: 10px 15px;
        display: block;
        text-align: left;
        font-weight: 400;
    }
    .has-childmenu .submenu li a:hover {
        background-color: #273167;
        color: white;
    }
    .has-childmenu:hover .submenu {
        transform: scaleY(1);
    }
    .home3 .has-childmenu .submenu li a:hover {
        background-color: #01d75f;
        color: white;
    }
}


/*  mobile menu  */


@media (max-width: 992px){
    .op-mobile-menu {
        position: fixed;
        top: 0;
        left: -250px;
        width: 250px;
        height: 105vh;
        background-color: #273167;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        overflow-y: auto; 
    }
    .op-mobile-menu .m-menu-header {
        padding: 20px 30px;
    }
    .op-mobile-menu .close-button {
        position: relative;
        width: 35px;
        height: 35px;
        background-color: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%; 
    }
    .op-mobile-menu .close-button:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
        height: 2px;
        margin-top: -1px;
        margin-left: -11px;
        background-color: #273167;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        transform-origin: center; 
    }
          
    .op-mobile-menu .close-button:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
        height: 2px;
        margin-top: -1px;
        margin-left: -11px;
        background-color: #273167;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        transform-origin: center; 
    }
    
    .mobile-menu-open .op-mobile-menu {
        left: 0; 
    }
    .nav-menu li.search-option {
        text-align: right;
        padding-right: 40px;
        margin-top: 20px;
    }
    .header-style-2 .nav-menu li.search-option {
        color: white;
    }
    .nav-menu li a {
        display: block;
        text-align: right;
        padding-right: 40px;
        font-weight: 400;
    }
    /* .nav-menu li a span:after {

    } */
    .nav-menu li.is-current>a span:after {
        height: 2px;
        bottom: -2px;
        opacity: 1;
        width: 100%;
        background-color: #fff;
    }
    .nav-menu li>a.active span:after {
        height: 2px;
        bottom: -2px;
        opacity: 1;
        width: 100%;
    }
    .header--fixed.sticky .nav-menu li a {
        color: white;
    }
    .header-style-2 .nav-menu li a {
        color: #fff;
    } 
    .header--fixed.sticky .nav-menu li.is-current>a span:after {
      height: 2px;
      bottom: -2px;
      opacity: 1;
      width: 100%;
      background-color: #fff;
    }
    .header--fixed.sticky .nav-menu li.search-option {
        color: white;
    }

    /* submenu */
    .has-childmenu {
        position: relative;
    }
    .has-childmenu .submenu {
        list-style: none;
        transform: scaleY(0);
        transform-origin: top;
        height: 0;
    }
    .has-childmenu .submenu li a {
        font-size: 85%;
        color: #fff;
        padding: 10px 15px;
        display: block;
        text-align: right;
        padding-right: 45px;
        font-weight: 400;
    }
    
    .has-childmenu .submenu.active {
        transform: scaleY(1);
        height: auto;
        transition: all 0.3s ease;
    }
    .has-childmenu .submenu li a.active {
      background-color: transparent !important;
    }
    .has-childmenu .submenu.active span {
        height: 2px;
        bottom: -2px;
        opacity: 1;
    }
  }


  @media (min-width: 1200px){
    .nav-menu li a {
        padding: 10px 15px;
    }
  }



  @media only screen and (max-width: 990px) {
    .op-mobile-menu .nav-menu > li {
      transform: translateX(-30px);
      transition: 0.7s ease;
      opacity: 0;
    }
    .mobile-menu-open .op-mobile-menu {
      left: 0;
    }
  
    .axsis-main-menu-area .nav-menu li.nav-item > a {
      padding: 15px 10px 15px 45px;
      display: block;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      color: #fff;
    }
  
    .mobile-menu-open {
      position: relative;
      overflow: hidden;
    }
    .mobile-menu-open .menu-bar {
      opacity: 0.1;
    }
    .mobile-menu-open .close-bar {
      transform: translateX(0px) !important;
      opacity: 1 !important;
      transition-delay: 0.2s !important;
    }
    .mobile-menu-open .mobile-menu-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(46, 60, 64, 0.9);
      z-index: 99;
      overflow: hidden;
    }
    /* .mobile-menu-open .axsis-main-menu-area {
      transform: translate3d(0%, 0, 0) scaleX(1);
      width: 70%;
      animation: scale-easeOutElastic;
    } */
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li {
      transform: translateX(0px);
      transition-delay: 0s;
      opacity: 1;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(1) {
      transition-delay: 0.2s;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(2) {
      transition-delay: 0.3s;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(3) {
      transition-delay: 0.4s;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(4) {
      transition-delay: 0.5s;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(5) {
      transition-delay: 0.6s;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(6) {
      transition-delay: 0.7s;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(7) {
      transition-delay: 0.8s;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(8) {
      transition-delay: 0.9s;
    }
    .mobile-menu-open .axsis-main-menu-area .nav-menu > li:nth-child(9) {
      transition-delay: 1s;
    }
  
    @keyframes scale-easeOutElastic {
      0% {
        transform: scale(1);
      }
      16% {
        transform: scale(-0.32);
      }
      28% {
        transform: scale(0.13);
      }
      44% {
        transform: scale(-0.05);
      }
      59% {
        transform: scale(0.02);
      }
      73% {
        transform: scale(-0.01);
      }
      88% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }
  }
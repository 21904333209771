
.screenshot-section {
    position: relative;
    overflow: hidden;
    background-color: #f8fbff;
}
.angleShape {
    position: relative;
  }
  .angleShape:after {
    content: "";
    position: absolute;
    left: 48%;
    top: 50%;
    width: 1273px;
    height: 1000px;
    -webkit-border-radius: 150px;
    -moz-border-radius: 150px;
    border-radius: 120px;
    background-color: #eef3fa;
    z-index: 0;
    transform: skew(-15deg) rotate(38deg) translateY(-50%);
  }

  .circleShapeleft {
    position: relative;
  }
  .circleShapeleft:after {
    content: "";
    position: absolute;
    left: -150px;
    bottom: 8%;
    width: 300px;
    height: 300px;
    background-image: url(../images/circle-shape.png);
    background-size: cover;
  }
.container.position-relative {
    z-index: 1;
}

.screenshot-info-item {
    padding: 20px 30px;
    background-color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.screenshot-info-item .icon {
    width: 55px;
    height: 55px;
    font-size: 20px;
    line-height: 52px;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #6f64e7;
    color: #fff;
    transition: all 0.3s ease;
}
.screenshot-info-item .content {
    width: calc(100% - 55px);
    padding-left: 20px;
}
.screenshot-info-item .content p {
    margin-bottom: 0;
    transition: all 0.3s ease;
}
.screenshot-info-item .icon svg,
.screenshot-info-item .content h6 {
    transition: all 0.3s ease;
}
.screenshot-info-item:hover {
    background-color: #6f64e7;
}
.screenshot-info-item:hover .icon {
    background-color: white;
}
.screenshot-info-item:hover .icon svg {
    fill: #6f64e7;
}
.screenshot-info-item:hover .content p {
    color: white;
}
.screenshot-info-item:hover .content h6 {
    color: white;
}


.screenshot-info-item-style2 .icon svg {
    width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 25px;
    stroke: #6f64e7;
}
.screenshot-info-item-style2 .content {
    width: calc(100% - 35px);
    padding-left: 15px;
}
.screenshot-info-item-style2 .content p {
    margin-bottom: 0;
}

/* custom btn */
.custom-btn {
    padding: 16px 35px;
    border: 2px solid #6f64e7;
    text-align: center;
    display: inline-block;
    color: #6f64e7;
    text-decoration: none;;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.screenshot-content .custom-btn {
    min-width: 170px;
}
.screenshot-content .custom-btn:hover {
    text-decoration: none;
    background-color: #6f64e7;
    color: #fff;
    box-shadow: 0 5px 10px 0 rgba(111, 100, 231, 0.1);
}


@media (min-width: 1500px){
    .screenshot-info-item {
        padding: 30px;
    }
    .screenshot-info-item .content {
        padding-left: 30px;
    }
    .screenshot-info-item-style2 {
        margin-bottom: 25px;
    }
    .screenshot-info-item-style2 .icon svg {
        width: 25px;
        height: 25px;
        font-size: 20px;
        line-height: 30px;
    }
    .screenshot-image.xl-image {
        width: 730px;
        transform: translateX(-100px);
    }
}

/* home 2 */
.screenshot-image.style2 {
    position: relative;
  }
  .screenshot-image.style2:after {
    content: "";
    position: absolute;
    left: 20%;
    top: -6%;
    width: 150px;
    height: 150px;
    background-image: url(../images/circle-shape2.png);
    background-size: cover;
  }
  @media (min-width: 992px) {
    .screenshot-image.style2:after {
      width: 300px;
      height: 300px;
      left: 5%;
      top: -20%;
    }
  }
  .screenshot-image.style2:before {
    content: "";
    position: absolute;
    left: 10%;
    top: 0;
    width: 150px;
    height: 150px;
    background-color: #e5e3fb;
    border-radius: 50%;
  }
  @media (min-width: 992px) {
    .screenshot-image.style2:before {
      width: 300px;
      height: 300px;
      left: -5%;
    }
  }

  .screenshot-image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    position: relative;
    z-index: 2;
  }
  .screenshot-info-item.style2 {
    background-color: transparent;
    padding: 20px;
    border-left: 6px solid #faf9ff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
  }
  .screenshot-info-item.style2 .icon {
    display: inline-block;
    margin-bottom: 20px;
  }
  .screenshot-info-item.style2 .content {
    width: calc(100% - 0px);
    padding-left: 0px;
  }
  .screenshot-info-item.style2:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    border-left: 6px solid #6f64e7;
  }
  .screenshot-info-item.style2:hover .icon {
    background-color: #6f64e7;
    color: white;
  }
  .screenshot-info-item.style2:hover .icon svg {
      fill: white;
  }
  .screenshot-info-item.style2:hover .content h6 {
    color: #273167;
  }
  .screenshot-info-item.style2:hover .content p {
    color: inherit;
  }


  @media (min-width: 1500px) {
    .xl-image {
      width: 700px;
      transform: translateX(-100px);
    }
    .xl-image.style3 {
      transform: translateX(100px);
    }
  }
  
  .screenshot-image.roundShape3 {
    position: relative;
  }
  .screenshot-image.roundShape3:after {
    content: "";
    position: absolute;
    left: -50px;
    top: -30px;
    width: 210px;
    height: 210px;
    background-image: url(../images/roundShape3.png);
    background-size: cover;
  }



  
.home3 .screenshot-info-item-style2 .svg-icon svg {
  fill: #01d55e;
}
.home3 .screenshot-content .custom-btn {
  border-radius: 5px !important;
  border: 2px solid #01d55e;
  color: #01d55e;
}
.home3 .screenshot-content .custom-btn.active {
  background-color: #01d55e;
  color: #fff;
  box-shadow: 0 5px 10px 0 rgba(111, 100, 231, 0.1);
}

.use-downlaod-area div {
  background-color: white;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  padding: 15px 25px;
  text-align: center;
  border-radius: 5px;
}
.use-downlaod-area div:last-child {
  margin-right: 0;
}
.use-downlaod-area div h2 {
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 0;
  line-height: 1.2;
}
.use-downlaod-area div h2 span {
  color: #01d55e;
}
.use-downlaod-area div p {
  margin-bottom: 0;
  color: #343d6f;
  font-weight: 500;
}

.screenshot-info-item.style3 {
  padding: 7px 0 !important;
  background-color: transparent;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
}
@media (min-width: 1500px) {
  .screenshot-info-item.style3 {
    padding: 30px;
  }
}
.screenshot-info-item.style3 .icon {
  width: 55px;
  height: 55px;
  font-size: 20px;
  line-height: 57px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(1, 213, 94, 0.3);
  color: #01d55e;
}
.screenshot-info-item.style3:nth-child(2) .icon {
  background-color: rgba(231, 172, 85, 0.3);
  color: #e7ac55;
}
.screenshot-info-item.style3:nth-child(3) .icon {
  background-color: rgba(69, 138, 227, 0.3);
  color: #458ae3;
}
.screenshot-info-item.style3 .content {
  width: calc(100% - 55px);
  padding-left: 20px;
}
.screenshot-info-item.style3:nth-child(1) .icon svg {
  fill: #01d55e;
}
.screenshot-info-item.style3:nth-child(2) .icon svg {
  fill: #e7ac55;
}
.screenshot-info-item.style3:nth-child(3) .icon svg {
  fill: #458ae3;
}
.screenshot-info-item.style3 .content {
  width: calc(100% - 55px);
  padding-left: 20px;
}
.screenshot-info-item.style3:hover .icon {
  color: inherit;
}
.screenshot-info-item.style3:hover .content p,
.screenshot-info-item.style3:hover .content h6 {
  color: inherit;
}
@media (min-width: 1500px) {
  .screenshot-info-item.style3 .content {
    padding-left: 30px;
  }
}
.screenshot-info-item.style3 .content p {
  margin-bottom: 0;
}


.home3 .screenshot-info-item-style2 .icon svg {
  stroke: #01d55e;
}
.home3 .custom-btn-group .custom-btn {
  border-radius: 5px !important;
  border: 2px solid #01d55e;
  color: #01d55e;
}
.home3 .custom-btn-group .custom-btn:hover {
  background-color: #01d55e;
  color: #fff;
  box-shadow: 0 5px 10px 0 rgba(111, 100, 231, 0.1);
}
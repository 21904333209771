.pricing-section.style2 .react-tabs,
.pricing-section.style2 .react-tabs__tab-panel--selected {
    position: relative;
}
.pricing-section.style2 .react-tabs:after {
    content: "";
    position: absolute;
    left: -140px;
    top: 25px;
    width: 150px;
    height: 150px;
    background-image: url(../images/roundShape2.png);
    background-size: cover;
    z-index: -1;
}
.pricing-section.style2 {
    overflow: hidden;
}
.pricing-section.style2 .react-tabs__tab-panel--selected:after {
    content: "";
    position: absolute;
    right: -180px;
    bottom: 25%;
    width: 150px;
    height: 150px;
    background-image: url(../images/circle-shape2.png);
    background-size: cover;
    z-index: -1;
}
.pricing-section.style2 .react-tabs__tab-panel--selected:before {
    content: "";
    position: absolute;
    right: -80px;
    bottom: 80px;
    width: 150px;
    height: 150px;
    background-color: #e5e3fb;
    border-radius: 50%;
    z-index: -1;
}
@media (min-width: 992px){
    .pricing-section.style2 .react-tabs:after {
        width: 300px;
        height: 300px;
    }
    .pricing-section.style2 .react-tabs__tab-panel--selected:before {
        width: 300px;
        height: 300px;
    }
    .pricing-section.style2 .react-tabs__tab-panel--selected:after {
        width: 300px;
        height: 300px;
    }
}
.contact-section.style2 .container {
    position: relative;
  }
  .contact-section.style2 .container:after {
    content: "";
    position: absolute;
    left: -70px;
    top: 20px;
    width: 150px;
    height: 150px;
    background-image: url(../images/roundShape2.png);
    background-size: cover;
    z-index: -1;
  }
  @media (min-width: 992px) {
    .contact-section.style2 .container:after {
      left: -150px;
      top: -15px;
      width: 300px;
      height: 300px;
    }
  }
